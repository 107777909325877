import React from 'react';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';

const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;

const PrivacyPolicy = () => {

  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {slug: 'leasing-maszyn-budowlanych'}
});

  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing maszyn budowlanych'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <HandleLoading loading={loading} error={error}>

           <h1>Leasing maszyn budowlanych</h1>

            <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

          </HandleLoading>

          {/* <div className={s_text.double}>
            <div>
              <p>
                Jak wyposażyć przedsiębiorstwo budowlane bez konieczności nadmiernego nadszarpnięcia ustalonego budżetu oraz
                utraty płynności finansowej? Rozwiązanie stanowi leasing maszyn budowlanych, który pozwala na finansowanie
                wszystkich możliwych maszyn i sprzętów niezbędnych na każdej budowie. Wybierając jedną z proponowanych
                przeze mnie ofert, nie muszą Państwo martwić się o przeważnie niemałe fundusze potrzebne na zakup tych
                urządzeń, a także uciążliwe formalności!
              </p>
            </div>
            <div>
              <p>
                Nabyte w minionym czasie doświadczenie oraz wieloletni staż pracy umożliwiają mi dostarczanie
                dla moich Klientów najkorzystniejszych propozycji leasingowych, uwzględniających ich
                indywidualne potrzeby i dopasowanych do konkretnej sytuacji.
              </p>
            </div>
          </div>

          <div className={s_text.double}>
            <div>
              <h2>Przedmiot oferty (maszyny nowe i używane)</h2>

              <ul className={s_text.checkmark_list}>
                <li>Koparki</li>
                <li>Ładowarki</li>
                <li>Wózki widłowe</li>
                <li>Wywrotki</li>
                <li>Spycharki</li>
                <li>Dźwigi</li>
                <li>Podnośniki</li>
                <li>Maszyny drogowe (walce, ubijarki, kruszarki)</li>
                <li>oraz wiele innych</li>
              </ul>
            </div>

            <div>
              <h2>Warunki leasingu maszyn budowlanych</h2>
              <ul className={s_text.dash_list}>
                <li>Opłata wstępna już od 5%</li>
                <li>Maksymalny czas trwania leasingu to 84 miesięcy</li>
                <li>Równe, sezonowe, degresywne raty</li>
                <li>Leasing możliwy również dla podmiotów działających w branży krócej niż 12 miesięcy</li>
                <li>Elastyczne procedury dotyczące oceny leasingowej Klienta</li>
              </ul>
              <p>
                Jeśli prowadzisz przedsiębiorstwo budowlane lub wykorzystujesz w codziennej pracy tego typu
                sprzęty i chcesz przy tym uzyskać więcej informacji dotyczących leasingu maszyn budowlanych,
                leasingu koparki, leasingu ładowarki, leasingu spycharki serdecznie zapraszam do kontaktu
                telefonicznego i e-mailowego. Pozostaję do Waszej dyspozycji oraz służę swoją wiedzą i wsparciem!
              </p>
            </div>
          </div> */}

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
